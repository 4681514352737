import { Injectable } from '@angular/core';
const TOKEN = 'X-Token';
const REFRESHTOKEN = 'X-RefreshToken'
@Injectable({
  providedIn: 'root'
})

export class LocalStorageService {

  constructor() { }

  storeTokens(tokens: {token:string, refreshToken:string}) {
    localStorage.setItem(TOKEN, tokens.token ?? '');
    localStorage.setItem(REFRESHTOKEN, tokens.refreshToken ?? '');
  }

  getAccessToken(): string | null {
    return localStorage.getItem(TOKEN);
  }

  getRefreshToken(): string | null {
    return localStorage.getItem(REFRESHTOKEN);
  }

  removeTokens():void {
    localStorage.removeItem(TOKEN);
    localStorage.removeItem(REFRESHTOKEN);
  }
}
